<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div class='table-search'>

          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='操作人'>
              <el-input v-model='searchCondition.username' placeholder='输入操作人姓名' clearable></el-input>
            </el-form-item>
            <el-form-item label=''>
              <template slot='label'>
                <el-link class='el-icon-refresh' @click='getOptions'>服务名称</el-link>
              </template>
              <!--              <el-input v-model='searchCondition.router' clearable></el-input>-->

              <el-select v-model='searchCondition.router' placeholder='请选择API路由及其服务名称' filterable clearable
                         style='width: 300px'>
                <el-option v-for='(option,index) in options' :key='index' :value='option.value'>{{ option.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label='处理状态'>-->
            <!--              <el-select placeholder='处理状态' v-model='searchCondition.deal_status' clearable>-->
            <!--                <el-option :label='label' :value='value' v-for='(label,value) in dealStatusMap' :key='value'>-->
            <!--                  <span style='float: left'>{{ label }} - {{ value }}</span>-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-row :gutter='10' class='mb8'>
          <el-col :span='1.5'>
            <el-button
              type='danger'
              icon='el-icon-delete'
              size='mini'
              :disabled='multiple'
              @click='handleDelete'

            >删除
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button
              type='danger'
              icon='el-icon-delete'
              size='mini'
              @click='handleClean'

            >清空3个月前数据
            </el-button>
          </el-col>

          <!-- <el-col :span="1.5">
            <el-button
              type="warning"
              icon="el-icon-download"
              size="mini"
              @click="handleExport"
              v-hasPermi="['system:config:export']"
            >导出</el-button>
          </el-col> -->

        </el-row>
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @selection-change='handleSelectionChange' border>
            <el-table-column type='selection' width='55' align='center' slot='first-column' />
            <el-table-column label='操作' width='100' align='center' fixed='right'>
              <template slot-scope='{row}'>
                <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>
              </template>
            </el-table-column>
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
      <!-- 操作日志详细 -->
      <el-dialog title='操作日志详细' :visible.sync='open' width='70%' append-to-body>
        <el-form ref='form' :model='form' label-width='100px' size='mini'>
          <el-row>
            <el-col :span='12'>

              <el-form-item label='操作模块：'>{{ form.service_name }}</el-form-item>

              <el-form-item
                label='登录信息：'
              >{{ form.username }} / {{ form.ip }}
              </el-form-item>

            </el-col>

            <el-col :span='24'>
              <el-form-item label='请求方式：'>{{ form.method }}</el-form-item>
            </el-col>

            <!--            <el-col :span='24'>-->
            <!--              <el-form-item label='操作方法：'>{{ form.method }}</el-form-item>-->
            <!--            </el-col>-->

            <el-col :span='24'>
              <el-form-item label='请求参数：'>
                <el-input :autosize='{ minRows: 15, maxRows: 30}' type='textarea'
                          :value='jsonRequestData'></el-input>
              </el-form-item>
            </el-col>

            <el-col :span='24'>
              <el-form-item label='返回参数：'>
                <el-input :autosize='{ minRows: 15, maxRows: 30}' type='textarea'
                          :value='jsonResult'></el-input>
              </el-form-item>

            </el-col>

            <!--            <el-col :span='12'>-->
            <!--              <el-form-item label='操作状态：'>-->
            <!--                <div v-if='form.status === 1'>正常</div>-->
            <!--                <div v-else>失败</div>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->

            <el-col :span='24'>
              <el-form-item label='操作时间：'>{{ form.created_at }}</el-form-item>
            </el-col>

          </el-row>
        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button @click='open = false'>关 闭</el-button>
        </div>
      </el-dialog>


    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'SystemOperLogList',
  components: { PageHeaderLayout },
  computed: {
    jsonResult() {
      if (this.form && this.form.response_data && this.form.response_data.indexOf('{') > -1)
        return JSON.stringify(JSON.parse(this.form.response_data), null, 4)
      else
        return this.form.response_data
      // return this.formData.notify_msg || {}
    },
    jsonRequestData() {
      if (this.form && this.form.request_data && this.form.request_data.indexOf('{') > -1)
        return JSON.stringify(JSON.parse(this.form.request_data), null, 4)
      else
        return this.form.request_data
      // return this.formData.notify_msg || {}
    }
  },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      loadingStatus: false,
      searchCondition: { deal_status: null, event_type: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '日志编号',
          value: 'id',
          width: 80
        },
        {
          title: '操作类型',
          value: 'method',
          width: 120
        },
        {
          title: 'API路由',
          value: 'router',
          width: 220
        },
        {
          title: '服务名称',
          value: 'service_name',
          width: 220
        },
        {
          title: '操作人员',
          value: 'username',
          width: 100
        },
        {
          title: '主机IP',
          value: 'ip',
          width: 200
        },
        {
          title: '备注',
          value: 'remark',
          width: 300
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      eventTypeArr: ['check_url', 'user_modify_org'],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
      currRow: {},
      dialogVisible: false,
      options: []
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.username) {
        condition.username = this.searchCondition.username
      }
      if (this.searchCondition.router) {
        condition.router = this.searchCondition.router.trim()
      }

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getSystemOperLogList(searchCondition)
      // list.map((item) => {
      //   item['deal_status_alias'] = this.dealStatusMap[item.deal_status]
      // })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })


    },
    async loadOptionMap() {
      let { event_types, deal_status } = await this.$api.getDingTalkEventOptionMap()
      this.eventTypeArr = [...event_types]
      this.dealStatusMap = { ...deal_status }
    },

    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const operIds = row.id || this.ids
      this.$confirm('是否确认删除日志编号为"' + operIds + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.deleteRows(operIds)
        // this.msgSuccess('删除成功')
        await this.getList()
      }).catch(function() {
      })
    },
    deleteRows(operIds) {
      let del_count = this.$api.deleteSystemOperLog({ 'ids': operIds })
      if (del_count) {
        this.$notify.success('删除成功')
      }
    },
    async clearLogs() {
      let { result } = await this.$api.clearSystemOperLog()

      this.$notify.success(`操作成功，符合条件的数据共计：${result.total || 0},本次清除：${result.clear_total || 0}`)
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$confirm('是否确认清空所有操作日志数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.clearLogs()
        // this.msgSuccess('清空成功')
        await this.getList()
      }).catch(function() {
      })
    },
    async getOptions() {
      let { options } = await this.$api.getSystemOperLogRouteOptions()
      console.log(options)
      this.options = options
    }
  },
  created() {
    this.getList()
    // this.loadOptionMap()
  }
}
</script>

<style scoped>

</style>